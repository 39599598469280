/* Color definitions */
.dmn-js-parent {
  --blue-base-65: #4d90ff;
  --blue-darken-48: #005df7;
  --blue-darken-48-opacity-30: rgba(0, 93, 247, 0.3);
  --blue-darken-55: #1a70ff;
  --blue-lighten-82: #a2c5ff;
  --red-base-62: #ff3d3d;
  --red-lighten-85: #ffb3b3;
  --silver-darken-80: #cdcdcd;
  --color-000000: #000;
  --color-000000-opacity-10: rgba(0, 0, 0, 0.1);
  --color-000000-opacity-20: rgba(0, 0, 0, 0.2);
  --color-404040: #404040;
  --color-444444: #444;
  --color-545454: #545454;
  --color-666666: #666;
  --color-888888: #888;
  --color-909090: #909090;
  --color-999999: #999;
  --color-aaaaaa: #aaa;
  --color-bfbfbf: #bfbfbf;
  --color-cccccc: #ccc;
  --color-dddddd: #ddd;
  --color-efefef: #efefef;
  --color-f6f6f6: #f6f6f6;
  --color-f8f8f8: #f8f8f8;
  --color-fafafa: #fafafa;
  --color-ffffff: #fff;
  --hint-button-color: var(--blue-base-65);
  --hint-button-hover-color: var(--blue-darken-48);
  --hint-invalid-color: var(--red-base-62);
  --separator-color: var(--color-dddddd);
  --input-border-color: var(--color-cccccc);
  --input-color: var(--color-444444);
  --input-focus-border-color: var(--blue-darken-55);
  --input-focus-box-shadow-color: var(--blue-lighten-82);
  --input-focus-box-shadow-inset-color: var(--silver-darken-80);
  --input-focus-invalid-border-color: var(--red-base-62);
  --input-focus-invalid-box-shadow-color: var(--red-lighten-85);
  --input-invalid-border-color: var(--red-base-62);
  --input-invalid-color: var(--red-base-62);
  --select-option-selected-color: var(--blue-darken-48);
  --select-option-hover-background-color: var(--color-000000-opacity-10);
  --select-options-background-color: var(--color-ffffff);
  --select-options-border-color: var(--color-cccccc);
  --select-options-box-shadow-color: var(--color-000000-opacity-10);
  --select-options-color: var(--color-444444);
}

.dms-list-component .items {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1.7em;
}

.dms-list-component .items .item .remove {
  float: right;
}

.dms-fill-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dms-fill-row > * {
  flex: 0 0 auto;
}

.dms-fill-row > :last-child {
  flex: 1 1 auto;
}

.dms-label {
  font-weight: bold;
  white-space: nowrap;
  margin-right: 5px;
}

.dms-hrule {
  border: 0;
  border-top: dotted 1px var(--separator-color);
  margin: 10px auto;
}

.dms-heading {
  margin: 12px 0 8px;
  font-weight: bold;
}

p.dms-hint button {
  color: var(--hint-button-color);
  text-decoration: none;
  display: inline;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  background: none;
}

p.dms-hint button:hover {
  color: var(--hint-button-hover-color);
}

p.dms-validation-warning {
  color: var(--hint-invalid-color);
}

h3.dms-heading {
  font-size: 16px;
}

h4.dms-heading {
  font-size: 14px;
}

.dms-input,
.dms-select {
  outline: none;
  padding: 3px 6px;
  border: 1px solid var(--input-border-color);
  background: none;
  font-size: 14px;
  color: var(--input-color);
  min-height: 26px;
}

.dms-input {
  min-height: 26px;
  white-space: pre-wrap;
}

.dms-select {
  height: 26px;
}

.dms-input-select .dms-input {
  padding-right: 24px;
}

.dms-input:focus,
.dms-select:focus {
  border: solid 1px var(--input-focus-border-color);
  box-shadow: 0 0 0 2px var(--input-focus-box-shadow-color), 0 0 0 1px var(--input-focus-box-shadow-inset-color) inset;
}

.dms-input.invalid,
.dms-select.invalid {
  border-color: var(--input-invalid-border-color);
  color: var(--input-invalid-color);
}

.dms-select.invalid:focus,
.dms-input.invalid:focus {
  border: solid 1px var(--input-focus-invalid-border-color);
  box-shadow: 0 0 0 2px var(--input-focus-invalid-box-shadow-color), 0 0 0 1px var(--input-focus-box-shadow-inset-color) inset;
}

.dms-validated-input.dms-block .dms-input,
.dms-block {
  display: block;
  width: 100%;
}

.dms-script-input {
  height: 150px;
  white-space: pre-wrap;
  overflow: auto;
}

.dms-input-select {
  display: inline-block;
  position: relative;
  min-width: 128px;
}

.dms-input-select .dms-input-select-icon {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}

.dms-input-select .options {
  background: var(--select-options-background-color);
}

.dms-select-options {
  position: absolute;
  background-color: var(--select-options-background-color);
  border: solid 1px var(--select-options-border-color);
  border-radius: 2px;
  font-size: 14px;
  color: var(--select-options-color);
  box-shadow: 1px 1px 1px 1px var(--select-options-box-shadow-color);
  z-index: 20;
  cursor: pointer;
}

.dms-select-options .option {
  padding: 4px 9px 4px 9px;
}

.dms-select-options .option.active {
  color: var(--select-option-selected-color);
}

.dms-select-options .option:focus,
.dms-select-options .option:hover {
  background-color: var(--select-option-hover-background-color);
}

.dmn-js-parent .content-editable--with-placeholder::before {
  content: attr(data-placeholder);
  position: absolute;
  opacity: 0.5;
}
