.dmn-decision-table-container {
  --action-icon-color: var(--blue-base-65);
  --action-icon-hover-background-color: var(--blue-darken-48);
  --action-icon-hover-color: var(--color-ffffff);
  --add-column-icon-color: var(--color-ffffff);
  --add-column-icon-background-color: var(--color-545454);
  --decision-table-color: var(--color-444444);
  --dmn-expression-language-background-color: var(--blue-darken-48-opacity-30);
  --dmn-expression-language-color: var(--color-ffffff);
  --dmn-expression-language-hover-background-color: var(--blue-base-65);
  --drag-and-drop-drag-color: var(--color-999999);
  --drag-and-drop-drop-marker-color: var(--color-666666);
  --hit-policy-explanation-color: var(--color-545454);
  --powered-by-overlay-background-color: var(--color-000000-opacity-20);
  --powered-by-overlay-content-background-color: var(--color-ffffff);
  --powered-by-overlay-content-color: var(--color-444444);
  --powered-by-overlay-content-border-color: var(--color-cccccc);
  --simple-mode-button-color: var(--color-444444);
  --simple-mode-button-background-color: var(--color-f6f6f6);
  --simple-mode-button-border-color: var(--color-cccccc);
  --simple-mode-button-box-shadow-color: var(--color-000000-opacity-10);
  --simple-mode-button-disabled-color: var(--color-cccccc);
  --table-border-color: var(--color-444444);
  --table-cell-border-color: var(--color-444444);
  --table-cell-selected-outline-color: var(--blue-darken-48);
  --table-foot-add-rule-border-color: var(--color-aaaaaa);
  --table-foot-add-rule-color: var(--color-aaaaaa);
  --table-foot-add-rule-hover-border-color: var(--color-444444);
  --table-foot-add-rule-hover-color: var(--color-444444);
  --table-head-border-color: var(--color-444444);
  --table-head-clause-color: var(--color-545454);
  --table-head-separator-color: var(--color-bfbfbf);
  --table-head-variable-color: var(--color-545454);
  --table-row-alternative-background-color: var(--color-f8f8f8);
  --view-drd-button-background-color: var(--color-ffffff);
  --view-drd-button-border-color: var(--color-cccccc);
  --view-drd-button-color: var(--color-444444);
  --view-drd-button-hover-background-color: var(--color-f6f6f6);

  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: var(--table-color);
  max-height: 100%;
}

.dmn-decision-table-container * {
  box-sizing: border-box;
}

/* basic table styles */

.dmn-decision-table-container .tjs-container {
  display: inline-grid;
  grid-template-columns: min-content auto;
}

.dmn-decision-table-container .tjs-table {
  grid-column: 1;
  grid-row: 3;
  width: min-content;
  table-layout: fixed;
  border-collapse: collapse;
  border: solid 1px var(--table-border-color);
}

.dmn-decision-table-container .tjs-table > tbody:empty {
  display: none;
}

.dmn-decision-table-container table thead {
  border-bottom: 3px double var(--table-head-border-color);
}

.dmn-decision-table-container td,
.dmn-decision-table-container th {
  border: solid 1px var(--table-cell-border-color);
  padding: 4px;
}

/* input and output headers */

.dmn-decision-table-container thead .input-cell,
.dmn-decision-table-container thead .output-cell {
  text-align: left;
}

.dmn-decision-table-container tfoot.add-rule .output-cell {
  border-left-color: var(--table-foot-add-rule-border-color);
}

.dmn-decision-table-container .tjs-table tr .output-cell {
  border-left: 3px double var(--table-cell-border-color);
}

.dmn-decision-table-container .tjs-table tr .output-cell + .output-cell {
  border-left: 1px solid var(--table-cell-border-color);
}

.dmn-decision-table-container .tjs-table tr .annotation {
  border-left: 3px double var(--tr-border-left-color);
}

.dmn-decision-table-container th.index-column {
  width: 56px;
}

.dmn-decision-table-container th {
  width: 192px;
  font-weight: normal;
}

.dmn-decision-table-container th.header {
  text-align: center;
}

.dmn-decision-table-container td.rule-index {
  text-align: right;
  padding-right: 8px;
}

.dmn-decision-table-container thead .type-ref,
.dmn-decision-table-container .script-editor {
  font-family: monospace;
}

.dmn-decision-table-container .tjs-table tbody tr:nth-child(2n) {
  background-color: var(--table-row-alternative-background-color);
}

.dmn-decision-table-container .input-cell.add-rule,
.dmn-decision-table-container .input-cell :not(.focussed).empty {
  text-align: center;
}

.dmn-decision-table-container .cell {
  position: relative;
  white-space: pre-wrap;
}

/* end basic table styles */

/* selection styles */

.dmn-decision-table-container th,
.dmn-decision-table-container h3,
.dmn-decision-table-container h5 {
  position: relative;
}

.dmn-decision-table-container .selected {
  outline: var(--table-cell-selected-outline-color);
  outline-offset: -1px;
}

.dmn-decision-table-container th.selected:not(.focussed) {
  background: var(--selected-not-focused-border-color);
}

/* end selection styles */

/* footer styles */

.dmn-decision-table-container tfoot.add-rule td.add-rule-add {
  text-align: right;
}

.dmn-decision-table-container tfoot.add-rule td {
  border-color: var(--table-foot-add-rule-border-color);
  color: var(--table-foot-add-rule-color);
}

.dmn-decision-table-container tfoot.add-rule:hover td {
  border-color: var(--table-foot-add-rule-hover-border-color);
  color: var(--table-foot-add-rule-hover-color);
}

/* end footer styles */

/* content editable styles */

.dmn-decision-table-container [contenteditable],
.dmn-decision-table-container [tabindex] {
  outline: none;
}

/* end content editable styles */

/* context menu styles */

/* decision table header */
.dmn-decision-table-container .decision-table-header {
  text-align: left;
  padding: 10px;
}

.dmn-decision-table-container .decision-table-header * {
  vertical-align: middle;
}

/* decision table properties */
.dmn-decision-table-container .decision-table-properties {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  border: 1px solid var(--color-000000);
  border-bottom: none;
  padding: 10px;
  align-items: center;
}

.dmn-decision-table-container .decision-table-name {
  position: relative;
  display: block;
  flex-grow: 1;
  margin: 0;
  width: 0;
  min-width: 100px;
  max-width: min-content;
  font-size: 21px;
  font-weight: normal;
}

.dmn-decision-table-container .decision-table-name:not(.editable),
.dmn-decision-table-container .decision-table-name .content-editable {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dmn-decision-table-container .decision-table-name.focussed .content-editable {
  text-overflow: unset;
}

.dmn-decision-table-container .decision-table-header-separator {
  display: block;
  height: 32px;
  border-left: 2px solid var(--table-head-separator-color);
  margin: 0 10px;
}

.dmn-decision-table-container .hit-policy {
  display: block;
}

.dmn-decision-table-container .hit-policy-explanation {
  margin-left: 5px;
  color: var(--hit-policy-explanation-color);
}

/* end decision table properties */

.dmn-decision-table-container thead .input-label,
.dmn-decision-table-container thead .input-expression,
.dmn-decision-table-container thead .output-label,
.dmn-decision-table-container thead .output-name {
  margin: 16px 6px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dmn-decision-table-container .input-cell .input-label,
.dmn-decision-table-container .input-cell .output-label,
.dmn-decision-table-container .input-cell .output-name {
  display: block;
}

.dmn-decision-table-container thead .input-expression:empty::before {
  content: 'Input';
  opacity: 0.5;
}

.dmn-decision-table-container thead .output-name:empty::before {
  content: 'Output';
  opacity: 0.5;
}

.dmn-decision-table-container thead .input-variable,
.dmn-decision-table-container thead .output-variable {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.dmn-decision-table-container thead .input-cell .clause,
.dmn-decision-table-container thead .output-cell .clause {
  font-size: 12px;
  text-align: left;
  color: var(--table-head-clause-color);
}

.dmn-decision-table-container thead .input-cell .input-variable,
.dmn-decision-table-container thead .output-cell .output-variable {
  font-size: 12px;
  color: var(--table-head-variable-color);
}

/** actionable icon */
.dmn-decision-table-container .action-icon {
  margin-left: 5px;
  border-radius: 2px;
  box-shadow: 0 0 0 2px white;
  color: var(--action-icon-color);
}

.dmn-decision-table-container .action-icon::before {
  margin-left: 0.1em;
  margin-right: 0.1em;
}

.dmn-decision-table-container .create-inputs .action-icon,
.dmn-decision-table-container .add-input .action-icon,
.dmn-decision-table-container .add-output .action-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 0;
  border-radius: 50%;
  color: var(--add-column-icon-color);
  background-color: var(--add-column-icon-background-color);
}

.dmn-decision-table-container .actionable:hover .action-icon {
  background-color: var(--action-icon-hover-background-color);
  color: var(--action-icon-hover-color);
}

.dmn-decision-table-container .input-editor .add-input,
.dmn-decision-table-container .output-editor .add-output {
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translate(50%, -50%);
  z-index: 2;
  border-radius: 100%;
  padding: 6px;
}

/** end actionable icon */

/* view drd */
.dmn-decision-table-container .view-drd {
  grid-column: 1;
  grid-row: 1;
  margin-bottom: 10px;
}

.dmn-decision-table-container .view-drd .view-drd-button {
  background: var(--view-drd-button-background-color);
  padding: 8px;
  border: solid 1px var(--view-drd-button-border-color);
  border-radius: 2px;
  font-size: 14px;
  color: var(--view-drd-button-color);
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.dmn-decision-table-container .view-drd .view-drd-button:hover {
  background: var(--view-drd-button-hover-background-color);
}

/* end view drd */

/* powered by */
.dmn-js-parent {
  /* required to apply position: fixed correctly */
  transform: translate(0);
}

.dmn-decision-table-container .powered-by {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin: 10px;
  background-color: var(--color-ffffff);
}

.dmn-decision-table-container .powered-by__logo {
  z-index: 10;
}

.dmn-decision-table-container .powered-by-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--powered-by-overlay-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.dmn-decision-table-container .powered-by-overlay .powered-by-overlay-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  max-width: 260px;
  background: var(--powered-by-overlay-content-background-color);
  color: var(--powered-by-overlay-content-color);
  border: solid 1px var(--powered-by-overlay-content-border-color);
  border-radius: 2px;
  font-size: 14px;
}

.dmn-decision-table-container .powered-by-overlay .powered-by-overlay-content .logo {
  margin: 15px 20px 15px 10px;
}

/* end powered by */

/* simple mode */

.dmn-decision-table-container .simple-mode-button {
  color: var(--simple-mode-button-color);
  background-color: var(--simple-mode-button-background-color);
  position: absolute;
  padding: 4px;
  border-radius: 2px;
  border: solid 1px var(--simple-mode-button-border-color);
  cursor: pointer;
  font-size: 14px;
  z-index: 2;
  opacity: 0.8;
  box-shadow: 1px 1px 1px 1px var(--simple-mode-button-box-shadow-color);
}

.dmn-decision-table-container .simple-mode-button:hover {
  opacity: 1;
}

.dmn-decision-table-container .simple-mode-button.disabled,
.dmn-decision-table-container .simple-mode-button.disabled:hover {
  color: var(--simple-mode-button-disabled-olor);
}

/* end simple mode  */

/**
 * badges
 */
.dms-badge {
  border-radius: 2px;
  padding: 3px 6px;
  font-size: 0.8em;
  height: 19px;
}

.dms-badge-icon + .dms-badge-label {
  margin-left: 3px;
}

.dms-badge.dmn-expression-language {
  background: var(--dmn-expression-language-background-color);
  color: var(--dmn-expression-language-color);
}

.dms-badge + .dms-badge {
  margin-left: 3px;
}

.dmn-decision-table-container .dmn-expression-language .dms-badge-label {
  display: none;
}

.dmn-decision-table-container .input-cell:hover .dmn-expression-language .dms-badge-label,
.dmn-decision-table-container .output-cell:hover .dmn-expression-language .dms-badge-label {
  display: inline-block;
}

.dmn-decision-table-container .input-editor .dmn-expression-language {
  margin-top: 4px;
  display: inline-block;
}

.dmn-decision-table-container .cell .dms-badge.dmn-expression-language {
  z-index: -1;
}

.dmn-decision-table-container .cell:hover .dms-badge.dmn-expression-language {
  background: var(--dmn-expression-language-hover-background-color);
  z-index: 1;
}

/* cell expression language */

.dmn-decision-table-container .cell .dmn-expression-language {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 2px;
  pointer-events: none;
}

/* end cell expression language */

/* drag and drop */
.dmn-decision-table-container .dragover {
  position: relative;
}

.dmn-decision-table-container .dragover::before {
  content: '';
  display: block;
  position: absolute;
  background-color: var(--drag-and-drop-drop-marker-color);
  border-radius: 5px;
  z-index: 2;
  pointer-events: none;
}

.dmn-decision-table-container .dragover.top::before {
  left: -6px;
  right: -6px;
  height: 5px;
  top: -3px;
}

.dmn-decision-table-container .dragover.right::before {
  top: -6px;
  bottom: -6px;
  width: 5px;
  right: -3px;
}

.dmn-decision-table-container .dragover.bottom::before {
  left: -6px;
  right: -6px;
  height: 5px;
  bottom: -3px;
}

.dmn-decision-table-container .dragover.left::before {
  top: -6px;
  bottom: -6px;
  width: 5px;
  left: -3px;
}

.dmn-decision-table-container .dragged {
  color: var(--drag-and-drop-drag-color);
}

/* cell description */

.dmn-decision-table-container .description-indicator {
  position: absolute;
  top: 0;
  right: -4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--color-000000);
  transform: rotate(45deg);
  transform-origin: top;
}

/* end cell description */
