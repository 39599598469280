.dmn-drd-container {
  --dmn-definitions-background-color: var(--color-fafafa);
  --dmn-definitions-border-color: var(--color-cccccc);
  --dmn-definitions-focus-border-color: var(--color-aaaaaa);
  --drill-down-overlay-background-color: var(--blue-base-65);
  --drill-down-overlay-color: var(--color-ffffff);

  width: 100%;
  height: 100%;
  position: relative;
}

.dmn-drd-container * {
  box-sizing: border-box;
}

.djs-overlay .drill-down-overlay {
  font-size: 16px;
  background: var(--drill-down-overlay-background-color);
  color: var(--drill-down-overlay-color);
  text-align: left;
  border-radius: 1px;
  padding: 0 2px;
}

.dmn-definitions {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: var(--dmn-definitions-background-color);
  border: solid 1px var(--dmn-definitions-border-color);
  border-radius: 2px;
  padding: 4px;
}

.dmn-definitions .dmn-definitions-name {
  font-size: 1.5em;
  padding: 3px;
}

.dmn-definitions .dmn-definitions-name:focus {
  outline: none;
}

.dmn-definitions .dmn-definitions-id {
  font-family: monospace;
  margin-top: 2px;
  padding: 3px;
}

.dmn-definitions .dmn-definitions-id:focus {
  outline: none;
}

.dmn-definitions > [contenteditable]:hover,
.dmn-definitions > [contenteditable]:focus {
  padding: 2px;
  background-color: var(--color-ffffff);
  border-radius: 2px;
  border: 1px solid var(--dmn-definitions-focus-border-color);
}

.djs-container.with-palette .dmn-definitions {
  left: 80px;
}

.djs-container.with-palette-two-column .dmn-definitions {
  left: 130px;
}
