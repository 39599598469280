.dmn-decision-table-container {
  --allowed-values-placeholder-color: var(--color-cccccc);
  --cell-description-editor-border-color: var(--color-444444);
  --create-inputs-border-color: var(--color-aaaaaa);
  --create-inputs-color: var(--color-aaaaaa);
  --create-inputs-hover-color: var(--color-444444);
  --context-menu-background-color: var(--color-ffffff);
  --context-menu-border-color: var(--color-cccccc);
  --context-menu-color: var(--color-444444);
  --context-menu-box-shadow-color: var(--color-000000-opacity-10);
  --context-menu-group-entry-border-color: var(--silver-darken-80);
  --context-menu-group-entry-disabled-color: var(--color-cccccc);
  --context-menu-group-entry-hover-background-color: var(--color-efefef);
  --drag-and-drop-handle-color: var(--color-999999);
  --drag-and-drop-handle-hover-color: var(--color-444444);
  --form-control-label-color: var(--color-909090);
  --hint-color: var(--color-888888);
}

/* simple string edit */
.dmn-decision-table-container .simple-string-edit,
.dmn-decision-table-container .simple-date-edit {
  width: 250px;
}

.dmn-decision-table-container .input-expression-edit {
  width: 300px;
}

.dmn-decision-table-container .dms-form-control {
  padding: 9px 0;
}

.dmn-decision-table-container .allowed-values-edit .dms-form-control {
  display: flex;
  flex-direction: column;
}

.dmn-decision-table-container .allowed-values-edit .values {
  display: flex;
  flex-wrap: wrap;
}

.dmn-decision-table-container .allowed-values-edit .placeholder {
  color: var(--allowed-values-placeholder-color);
}

/** create inputs **/
.dmn-decision-table-container .create-inputs {
  white-space: normal;
  border-color: var(--create-inputs-border-color);
  color: var(--create-inputs-color);
  min-width: 50px;
  width: 50px;
}

.dmn-decision-table-container .create-inputs .add-input {
  margin-left: 0;
  margin-top: 15px;
  display: inline-block;
}

.dmn-decision-table-container .create-inputs:hover {
  border-color: var(--create-inputs-hover-color);
  color: var(--create-inputs-hover-color);
}

.dmn-decision-table-container th.create-inputs {
  vertical-align: top;
}

/** end create inputs **/

/* TODO(nikku): namespace and refactor context menu styles */
.dmn-decision-table-container .context-menu {
  position: absolute;
  background: var(--context-menu-background-color);
  border: solid 1px var(--context-menu-border-color);
  border-radius: 2px;
  font-size: 14px;
  color: var(--context-menu-color);
  z-index: 10;
  box-shadow: 1px 1px 1px 1px var(--context-menu-box-shadow-color);
}

.dmn-decision-table-container .context-menu .context-menu-container {
  margin: 8px;
}

.dmn-decision-table-container .context-menu p {
  margin: 10px 0;
}

.dmn-decision-table-container .context-menu .context-menu-flex {
  display: flex;
  flex-direction: column;
}

.dmn-decision-table-container .context-menu .context-menu-group .context-menu-group-entry {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding: 8px 16px;
  white-space: nowrap;
}

.dmn-decision-table-container .context-menu-group .context-menu-group-entry:hover {
  background-color: var(--context-menu-group-entry-hover-background-color);
}

.dmn-decision-table-container .context-menu .context-menu-group .context-menu-group-entry.disabled {
  pointer-events: none;
  color: var(--context-menu-group-entry-disabled-color);
}

.dmn-decision-table-container .context-menu .context-menu-group .context-menu-group-entry .context-menu-group-entry-icon {
  display: inline-block;
  text-align: center;
  width: 14px;
  margin-right: 6px;
}

.dmn-decision-table-container .context-menu .context-menu-group .context-menu-group-entry .context-menu-group-entry-icon::before {
  margin: 0;
}

.dmn-decision-table-container .context-menu-group:not(:last-child)::after {
  content: '';
  display: block;
  margin: 0 8px;
  border-bottom: 1px solid var(--context-menu-group-entry-border-color);
  height: 1px;
}

/* input/output editor */

.dmn-decision-table-container .context-menu .context-menu-container.input-edit,
.dmn-decision-table-container .context-menu .context-menu-container.output-edit {
  margin: 34px 10px 10px 10px;
}

.dmn-decision-table-container .context-menu .context-menu-container.type-ref-edit,
.dmn-decision-table-container .context-menu .context-menu-container.allowed-values-edit {
  margin: 10px;
}

.dmn-decision-table-container .dms-output-label,
.dmn-decision-table-container .dms-input-label {
  padding-right: 30px;
  padding-left: 0;
  border: none;
  font-size: 16px;
  white-space: nowrap;
}

.dmn-decision-table-container .dms-form-control:first-child {
  padding-top: 0;
}

.dmn-decision-table-container .dms-form-control .dms-label {
  display: block;
  margin: unset;
  margin-bottom: 6px;
  color: var(--form-control-label-color);
  font-weight: normal;
}

.dmn-decision-table-container .dms-form-control .dms-input {
  padding-right: 24px;
  border-radius: 3px;
}

.dmn-decision-table-container .dms-form-control .dms-list-component {
  max-width: 179px;
}

/* expression language */
.dmn-decision-table-container .context-menu .context-menu-group .context-menu-entry-set-expression-language {
  flex-direction: column;
}

.dmn-decision-table-container .context-menu .context-menu-group .context-menu-entry-set-expression-language:hover {
  background-color: unset;
}

.dmn-decision-table-container .context-menu .context-menu-group .context-menu-entry-set-expression-language .expression-language {
  margin-top: 8px;
}

.dmn-decision-table-container p.dms-hint {
  font-size: 0.85em;
  margin-top: 5px;
  color: var(--hint-color);
}

/* drag and drop */
.dmn-icon-drag {
  color: transparent;
}

.dmn-icon-drag:hover {
  color: var(--drag-and-drop-handle-hover-color);
}

.dmn-decision-table-container th .dmn-icon-drag {
  position: absolute;
  left: 4px;
  top: 10px;
  transform: translateY(-50%);
}

.dmn-decision-table-container th:hover .dmn-icon-drag,
.dmn-decision-table-container td:hover .dmn-icon-drag {
  color: var(--drag-and-drop-handle-color);
}

.dmn-decision-table-container .input-cell:hover .clause,
.dmn-decision-table-container .output-cell:hover .clause {
  visibility: hidden;
}

.dmn-icon-drag.horizontal,
.dmn-icon-drag.vertical {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* description editor */

.dmn-decision-table-container .context-menu .description-editor {
  margin: 0;
  padding: 5px 0;
  width: 192px;
  border-left: solid 3px var(--cell-description-editor-border-color);
}

.dmn-decision-table-container .description-editor .dms-input {
  border: none;
  min-height: 0;
}

/* end description editor */

.dmn-decision-table-container .resize-column-handle {
  width: 25px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -5px;
  z-index: 1;
  cursor: col-resize;
}
